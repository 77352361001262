var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SfTopBar", {
    staticClass: "topbar002",
    scopedSlots: _vm._u([
      {
        key: "left",
        fn: function () {
          return [_c("TopHeader")]
        },
        proxy: true,
      },
      {
        key: "right",
        fn: function () {
          return [
            _vm.hasCurrencyToSelect ? _c("CurrencySelector") : _vm._e(),
            _vm._v(" "),
            _vm.hasStoresToSelect ? _c("StoreSwitcher") : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }