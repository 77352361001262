var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header-main  headertoplogo" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", {
        staticClass: "headerlogo",
        domProps: { innerHTML: _vm._s(_vm.blocks) },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }