var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "header-top dk_show maintopbanner",
      staticStyle: { visibility: "hidden" },
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", {
          staticClass: "topbanner",
          domProps: { innerHTML: _vm._s(_vm.blocks) },
        }),
      ]),
      _vm._v(" "),
      _c("script", {
        attrs: {
          src: "https://frontend.malerifabrikken.com/assets/js/jquery-3.6.3.min.js",
        },
      }),
      _vm._v(" "),
      _c("script", {
        attrs: {
          src: "https://frontend.malerifabrikken.com/assets/js/slick.min.js",
        },
      }),
      _vm._v(" "),
      _c("script", [
        _vm._v(
          "\n\t\t$( document ).ready(function() {\n\t\t\t\n\t\t\tsetTimeout(function () {\n\t\t\t\t$('.maintopbanner').show();\n                $('.topbanner').slick({\n\t\t\t\tdots: false,\n\t\t\t\t autoplay: true,\n\t\t\t\tautoplaySpeed: 8000,\n\t\t\t\tappendDots: '.slider-dots',\n\t\t\t\tarrows: false,\n\t\t\t\tinfinite: false,\n\t\t\t\tslidesToScroll: 1,\n\t\t\t\tslidesToShow: 1,\n\t\t\t\taccessibility: true,\n\t\t\t\tvariableWidth: false,\n\t\t\t\tfocusOnSelect: true,\n\t\t\t\tcenterMode: false,\n\t\t\t})\n$('.maintopbanner').css('visibility', 'visible');\n\t\t\t\t \n            }, 2000);\n\n\t\t});\n\t"
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }