var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("IconSprite"),
      _vm._v(" "),
      _vm.isCartSidebarOpen ? _c("CartSidebar") : _vm._e(),
      _vm._v(" "),
      _vm.isWishlistSidebarOpen ? _c("WishlistSidebar") : _vm._e(),
      _vm._v(" "),
      _vm.isLoginModalOpen
        ? _c("LoginModal", { on: { close: _vm.toggleLoginModal } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "LazyHydrate",
        { attrs: { "when-visible": "" } },
        [_c("Notification")],
        1
      ),
      _vm._v(" "),
      _c("TopBar"),
      _vm._v(" "),
      _c("HeaderTopLogo"),
      _vm._v(" "),
      _c("AppHeader"),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { id: "main_section" } },
        [_c("nuxt", { key: _vm.route.fullPath })],
        1
      ),
      _vm._v(" "),
      _c("BottomNavigation"),
      _vm._v(" "),
      _c("LoadWhenVisible", [_c("FooterSection")], 1),
      _vm._v(" "),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { display: "none" }, attrs: { id: "back-top" } },
      [
        _c("a", { staticClass: "nav_up", attrs: { href: "#top" } }, [
          _vm._v("Back to top"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }