






import { defineComponent, ref, reactive, useFetch,computed, onMounted } from '@nuxtjs/composition-api';
import { useContent } from '~/composables';
export default defineComponent({
  name: 'HeaderLogo',
  setup(props) {
	  const {
		loadBlocks,
	  } = useContent();
	   const blocks = ref();
	   const blockId = 'headertoplogo';
		useFetch(async () => { 
				const res = await loadBlocks({ identifiers: [blockId] });
				blocks.value = res[0] ? res[0].content : ''
		});	  
	  return {
		blocks
	  };
  }
});
