var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SfLink",
    {
      staticClass: "sf-header-navigation-item__link",
      attrs: { link: _vm.link },
    },
    [
      _vm._v("\n  " + _vm._s(_vm.label) + "\n  "),
      _vm.hasChildren
        ? _c("SfIcon", {
            attrs: {
              icon: "chevron_down",
              size: "xxs",
              color: "green-primary",
              viewBox: "0 0 24 24",
              coverage: 1,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }